import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Capabilities from 'Components/Capabilities';
import { initDocumentationLogin } from 'Store/auth/actions';

import './styles/styles.scss';

function Home() {
  const dispatch = useDispatch();

  function openDocumentation() {
    dispatch(initDocumentationLogin());
  }

  return (
    <main className="ipex-content">
      <div className="text-container">
        <h1>Welcome to DEXF!</h1>
        <p className="lead">
          The Digital Experience Foundation (DEXF) connects different Application Programming
          Interfaces (API), micro services in the cloud and source systems from Range & Supply and
          Retail.
        </p>
        <p>
          Below are links to our web applications where you as a user can make changes to your data
          and manage your settings in DEXF.
        </p>
        <p>
          Find more information about DEXF and our full range of capabilities by following this link
          to the{' '}
          <a
            href="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/overview"
            rel="noreferrer"
            target="_blank"
            className="show-as-link"
          >
            DEXF documentation
          </a>
          .
        </p>
        <p>
          If there are problems accessing the documentation, please try our new documentation site{' '}
          <span className="show-as-link" onClick={() => openDocumentation()}>
            DEXF documentation Beta
          </span>
          .
        </p>
      </div>
      <Capabilities />
    </main>
  );
}

export default withRouter(Home);
