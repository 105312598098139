import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPong } from 'Store/ping/actions';

import { Button, IpexIcon } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/';

class Ping extends React.Component {
  constructor(props) {
    super(props);
    this.handlePingRequest = this.handlePingRequest.bind(this);
  }

  handlePingRequest() {
    this.props.getPong();
  }
  render() {
    return (
      <main className="ipex-content">
        <h2>Ping the DEXF Setting service</h2>
        {!this.props.isAuth && (
          <p className="lead">
            <IpexIcon iconType="information-circle" iconStyle="md" iconColor="blue" />
            Your user must be in the dexf_service scope.
          </p>
        )}
        {this.props.isAuth && <Button label={'Ping'} onClick={() => this.handlePingRequest()} />}
        {this.props.pingData !== '' && (
          <p className="lead">
            <IpexIcon icontype="information-circle" iconstyle="md" iconcolor="blue" />
            {this.props.pingData}
          </p>
        )}
      </main>
    );
  }
}

function mapStateToProps(state) {
  console.log(state.ping);
  const isAuth = state.auth.isAuth;
  const pingData = state.ping.pingData;
  return {
    isAuth,
    pingData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPong() {
      dispatch(getPong());
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ping));
