import React from 'react';
import Capability from 'Components/Capability';
import configImage from './images/application_configuration.jpg';
import vpcImage from './images/application_design_storage.jpg';
import authImage from './images/iam.jpg';
import itemImage from './images/build_your_own_item.jpg';
import assetImage from './images/range_asset_management.jpg';
import operationImage from './images/operation.jpg';
import settingImage from './images/setting.jpg';
import notifierImage from './images/notifier.jpg';
import retailImage from './images/retail.jpg';
import saveImage from './images/save.jpg';

import './styles/styles.scss';

class Capabilities extends React.Component {
  render() {
    const serviceurl = window.location.origin;

    return (
      <>
        <h3>Capabilities</h3>
        <div id="wrapper" className="Capability-container">
          <Capability
            serviceName="DEXF Settings"
            title="Settings Service"
            imageSource={settingImage}
            imageAltText="Link to Settings Service"
            text="Handle all your application settings here. There is also a Gallery section where experiences can be maintaned on a global and kiosk level."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/35836231730/Customize+your+application"
            buttonText="Launch Settings"
            buttonLink={serviceurl + '/setting/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Custom Item Service"
            title="Build Your Own Item"
            imageSource={itemImage}
            imageAltText="Link to Build your own item, Custom Item Service"
            text="DEXF offers the possibility to create items that are not in PIA, this can be changes to already existing items."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/1771995245/Build+your+own+item"
            buttonText="Build Your Own Item"
            buttonLink={serviceurl + '/item/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Config Service"
            title="Application Configuration"
            imageSource={configImage}
            imageAltText="Link to Application Configuration, Config Service"
            text="DEXF application configuration makes it possible to collect and manage the range of an application."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/1771438254/Application+Configuration"
            buttonText="Application Configuration"
            buttonLink={serviceurl + '/config/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Asset Service"
            title="Range Asset Management"
            imageSource={assetImage}
            imageAltText="Link to Range Asset Management Service"
            text="DEXF offers the ability to upload assets and connect them to one or more items."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/2626191658/Range+Asset+Management"
            buttonText="Range Asset Management"
            buttonLink={serviceurl + '/asset/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Operation Service"
            title="Operation"
            imageSource={operationImage}
            imageAltText="Link to Operation Service"
            text="DEXF operation service offers tools for maintaining and working with DEXF data. It’s mainly accessed by the DEXF team and Second level support."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/overview"
            buttonText="Operation"
            buttonLink={serviceurl + '/operation/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF VPC Service"
            title="Design Storage - Anonymous"
            imageSource={vpcImage}
            imageAltText="Link to Application design storage, VPC Service"
            text="DEXF provides the possibility to save a design made in an application to be used later."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/1768688254/Application+Design+Storage"
            buttonText="Application Design Storage"
            buttonLink={serviceurl + '/vpc/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Save"
            title="Design Storage - Personal"
            imageSource={saveImage}
            imageAltText="Link to Personal Design Storage, Save Service"
            text="Personal Design Storage provides the possibility to save a design to be used later."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/overview"
            buttonText="Save"
            buttonLink={serviceurl + '/save/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Auth Service"
            title="IAM"
            imageSource={authImage}
            imageAltText="Link to IAM, Auth Service"
            text="DEXF offers support for customers to secure their digital solutions for co-workers and administer rights using privileges and roles."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/2289860736/IAM"
            buttonText="IAM"
            buttonLink={serviceurl + '/auth/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Notifier Service"
            title="Email and SMS Notifications"
            imageSource={notifierImage}
            imageAltText="Link to Email and SMS Notifications, Notifier Service"
            text="DEXF offers the possibility to send email and SMS notifications. It is also possible to create your own e-mail templates."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/1783562395/Email+and+SMS+notifications"
            buttonText="Notifier"
            buttonLink={serviceurl + '/notifier/v1/ui/home'}
          />
          <Capability
            serviceName="DEXF Retail"
            title="Retail Service"
            imageSource={retailImage}
            imageAltText="Link to Retail Service"
            text="The Retail API provides retail information, both price and availability on market level. It also has the possibility to get the information on store level."
            linkSource="https://i-p-e-x.atlassian.net/wiki/spaces/DEXFD/pages/1744797870/Product+Retail"
            buttonText="Retail"
            buttonLink={serviceurl + '/retail/v1/ui/home'}
          />
        </div>
      </>
    );
  }
}

export default Capabilities;
