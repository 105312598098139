import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import store from './store';
import { unregister } from './registerServiceWorker';
import Auth from './utils/auth';
import axios from 'axios';
import dexfConfig from './devserver-env.js';
import dexfElevatedConfig from './devserver-elevatedEnv.js';
import authStorage from './utils/authStorage';
unregister();

const baseName = '/start/v1/ui';

authStorage.setSessionUrl();

// The react app is initialized here. However, before the react code runs we
// need to fetch some configuration from the backend which hosts the UI. Stuff
// like cognito configuration, service urls and api-keys are fetched in this way
// to avoid having to hard-code it in the javascript. Note that these calls are
// synchronous since we don't want to render the react app before they succeed.
if (process.env.NODE_ENV === 'development') {
  window.discovery = dexfConfig;
  window.discovery.start = dexfElevatedConfig.start;
  window.discovery.apiKey = dexfElevatedConfig.apiKey;
  new Promise((resolve, reject) => {
    resolve(
      Auth.configure({
        domain: window.discovery.oauth_domain,
        clientId: window.discovery.userpoolwebclientid,
        redirectUri: window.discovery.oauth_callback,
        logoutUri: window.discovery.oauth_signout,
        gaTrackingId: window.discovery.gatrackingid,
        gtmid: window.discovery.gtmid,
      })
    );
  })
    .then(() => Auth.idToken())
    .then((idToken) => {
      if (idToken) {
        window.discovery.apiKey = dexfElevatedConfig.apiKey;
        window.discovery.start = dexfElevatedConfig.start;
        window.discovery.documentation = dexfElevatedConfig.documentation;
      }
    })
    .catch((error) => {
      console.log('something went wrong!', error);
    })
    .finally(() => {
      const container = document.getElementById('root');
      const root = createRoot(container);
      root.render(
        <Provider store={store}>
          <BrowserRouter basename={baseName}>
            <App />
          </BrowserRouter>
        </Provider>
      );
    });
} else {
  axios
    .get('/start/v1/ui/env')
    .then((response) => {
      if (response.status === 200) {
        // The first call includes just what we need to login the user with
        // cognito.
        window.discovery = response.data;
        Auth.configure({
          domain: window.discovery.oauth_domain,
          clientId: window.discovery.userpoolwebclientid,
          redirectUri: window.discovery.oauth_callback,
          logoutUri: window.discovery.oauth_signout,
          gaTrackingId: window.discovery.gatrackingid,
          gtmid: window.discovery.gtmid,
        });
      } else {
        return Promise.reject('Environment call failed');
      }
    })
    .catch(function (error) {
      console.log('Something went wrong', error);
    })
    .then(() => Auth.idToken())
    .then((idToken) => {
      return axios({
        method: 'get',
        url: '/start/v1/ui/elevated/env',
        headers: {
          Authorization: 'Bearer ' + idToken,
        },
      });
    })
    .then((response) => {
      window.discovery.apiKey = response.data.apiKey;
      window.discovery.start = response.data.start;
      window.discovery.documentation = response.data.documentation;
    })
    .catch(() => {})
    .finally(() => {
      const container = document.getElementById('root');
      const root = createRoot(container);
      root.render(
        <Provider store={store}>
          <BrowserRouter basename={baseName}>
            <App />
          </BrowserRouter>
        </Provider>
      );
    });
}
