import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import auth from './auth/reducer';
import ping from './ping/reducer';
import Auth from '../utils/auth';
import IdleHandler from '../utils/idleHandler';

import * as authTypes from './auth/actionTypes';

const appReducer = combineReducers({
  auth,
  ping,
});

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  if (action.type !== authTypes.USER_LOGOUT) {
    Auth.idToken().catch(() => {
      next({ type: authTypes.USER_LOGOUT });
    });
  }
  next(action);
};

const checkIdleMiddleware = (store) => (next) => (action) => {
  if (action.type === authTypes.USER_LOGOUT) {
    IdleHandler.stop();
  } else if (action.type !== authTypes.USER_IDLE) {
    IdleHandler.reset();
  }

  next(action);
};

const middleware = applyMiddleware(
  thunkMiddleware,
  checkTokenExpirationMiddleware,
  checkIdleMiddleware,
  createLogger({ collapsed: true })
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middleware);

const rootReducer = (state, action) => {
  if (action.type === 'auth.USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer, enhancer);

export default store;
