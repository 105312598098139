import React from 'react';
import { Button } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/';

import './styles/styles.scss';

class Capability extends React.Component {
  handleButtonClick() {
    window.location.href = this.props.buttonLink;
  }

  render() {
    return (
      <div className="card__container">
        <a href={this.props.buttonLink} rel="noreferrer">
          <div className="card__image">
            <img
              src={this.props.imageSource}
              alt={this.props.imageAltText}
              className="image__size"
            />
          </div>
        </a>
        <div className="card__body__container">
          <div className="card__text">
            <p className="card__servicename">{this.props.serviceName}</p>
            <h6>{this.props.title}</h6>
            <p className="lead">{this.props.text}</p>
          </div>
          <div className="card__button">
            <Button label={this.props.buttonText} onClick={() => this.handleButtonClick()} />
          </div>
        </div>
      </div>
    );
  }
}

export default Capability;
