export const USER_LOGIN_INIT = 'auth.USER_LOGIN_INIT';
export const USER_LOGIN_SUCCESS = 'auth.USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'auth.USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'auth.USER_LOGOUT';

export const USER_IDLE = 'auth.USER_IDLE';
export const USER_ACTIVE = 'auth.USER_ACTIVE';

export const USER_DOCUMENTATION_LOGIN_INIT = 'auth.USER_DOCUMENTATION_LOGIN_INIT';
export const USER_DOCUMENTATION_LOGIN_SUCCESS = 'auth.USER_DOCUMENTATION_LOGIN_SUCCESS';
export const USER_DOCUMENTATION_LOGIN_FAILURE = 'auth.USER_DOCUMENTATION_LOGIN_FAILURE';
