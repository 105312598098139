import store from '../store/index';
import { USER_IDLE } from "../store/auth/actionTypes";

class IdleHandler {

  constructor() {
    this.idleTime = 1000 * 60 * 55;
  }

  _onIdle() {
    store.dispatch({ type: USER_IDLE });
  }

  start() {
    this.timeoutReference = window.setTimeout(this._onIdle, this.idleTime);
  }

  reset() {
    this.stop();
    this.start();
  }

  stop() {
    window.clearTimeout(this.timeoutReference);
  }
}

export default new IdleHandler();