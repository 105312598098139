import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import Auth from '../../utils/auth';
import { setUserActive } from '../../store/auth/actions';

import { GlobalMessage, Button, IpexIcon } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/';

class ActiveConfirmation extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.idleTime = 1000 * 30; // Give user 30 seconds to confirm user is still present
  }

  handler() {
    Auth.signOutSingle();
  }

  start() {
    this.timeoutReference = window.setTimeout(this.handler, this.idleTime);
  }

  stop() {
    window.clearTimeout(this.timeoutReference);
  }

  userIsActive() {
    this.stop();
    this.props.setUserActive();
  }

  componentDidMount() {
    this.start();
  }

  render() {
    return (
      <GlobalMessage>
        <React.Fragment>
          <div>
            <h6>Inactivity</h6>
            <p>
              <IpexIcon icontype="information-circle" iconstyle="md" iconcolor="gray-75" />
              You will be logged out due to inactivity. Do you want to remain logged in?
            </p>
          </div>
          <div>
            <Button label="Yes" onClick={this.userIsActive} isOutlined />
          </div>
        </React.Fragment>
      </GlobalMessage>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserActive() {
      dispatch(setUserActive());
    },
  };
}

export default connect(null, mapDispatchToProps)(ActiveConfirmation);
