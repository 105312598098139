import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initLogin } from 'Store/auth/actions';
import axios from 'axios';
import Auth from '../../utils/auth';
import dexfElevatedConfig from '../../devserver-elevatedEnv.js';
import { AccessPrompt } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components';
import authStorage from '../../utils/authStorage.js';

function Callback(props) {
  const dispatch = useDispatch();

  const handleCallback = useCallback(() => {
    Auth.callback()
      .then(() => Auth.idToken())
      .then((idToken) => {
        const configurationPromise = axios({
          method: 'get',
          url:
            process.env.NODE_ENV === 'development'
              ? dexfElevatedConfig.start + '/ui/elevated/env'
              : '/start/v1/ui/elevated/env',
          headers: {
            Authorization: 'Bearer ' + idToken,
          },
        });
        return Promise.all([idToken, configurationPromise]);
      })
      .then((data) => {
        const configuration = data[1];
        window.discovery.start = configuration.data.start;
        window.discovery.documentation = configuration.data.documentation;
        window.discovery.apiKey = configuration.data.apiKey;

        const idToken = data[0];
        dispatch(initLogin(idToken));
        props.history.replace(authStorage.getPathAfterReload());
      })
      .catch((error) => {
        console.log(error);
        props.history.replace('/login');
      });
  }, [dispatch, props.history]);

  useEffect(() => {
    handleCallback();
  }, [handleCallback]);

  return (
    <AccessPrompt>
      <div>Logging you in...</div>
    </AccessPrompt>
  );
}

export default withRouter(Callback);
