import * as types from './actionTypes.js';

const initialState = {
  loading: false,
  pingData: ''
};

export default function PingReducer (state = initialState, action) {
  switch (action.type) {
    case types.PING_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.PING_SUCCESS:
      console.log(action)
      return {
        ...state,
        pingData: action.data.data,
        loading: false,
      };
    case types.PING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
