import React from 'react';
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import Auth from '../../utils/auth';
import { initLogin } from 'Store/auth/actions';
import { AccessPrompt } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components';
import authStorage from '../../utils/authStorage';

class Login extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  checkToken() {
    Auth.idToken()
      .then((idToken) => {
        this.props.initLogin(idToken);
        this.props.history.replace(authStorage.getPathAfterReload());
      })
      .catch(() => {
        Auth.signIn();
      });
  }
  componentDidMount() {
    this.checkToken();
  }

  render() {
    return (
      <AccessPrompt>
        <div>Redirecting to login...</div>
      </AccessPrompt>
    );
  }
}

function mapStateToProps(state) {
  const isLoggedIn = state.auth.isLoggedIn;
  return {
    isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initLogin(token) {
      dispatch(initLogin(token));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
