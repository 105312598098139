import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import Start from 'Containers/Start';
import LoginErrorPage from 'Containers/LoginErrorPage';
import Login from 'Containers/Login';
import LoggedOut from 'Containers/LoggedOut';
import Callback from 'Containers/Callback';
import Home from 'Containers/Home/';
import Ping from 'Containers/Ping/';
import ActiveConfirmation from 'Containers/ActiveConfirmation';
import Redirect from 'Containers/Redirect';

import PrivateRoute from 'Helpers/PrivateRoute';
import { DexfHeader, Footer } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/';
import Auth from './utils/auth';

class App extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  handleLogout() {
    Auth.signOut();
    sessionStorage.clear('path');
  }

  render() {
    return (
      <div className="App">
        {this.props.isIdle && <ActiveConfirmation />}
        <React.Fragment>
          {' '}
          <DexfHeader
            servicename={'Start'}
            domain={'DEXF'}
            serviceurl={window.location.origin}
            username={
              this.props.userData['identities'] &&
              this.props.userData['identities'].map((user) => {
                return user.userId;
              })
            }
            onlogout={() => this.handleLogout()}
            gatrackingid={window.discovery.gatrackingid}
            gtmid={window.discovery.gtmid}
          />
        </React.Fragment>

        <div className="ipex-container">
          <Switch>
            <Route path="(/)" component={Start} />
            <PrivateRoute path="/home" component={Home} authenticated={this.props.isLoggedIn} />
            <PrivateRoute
              path="/ping"
              component={Ping}
              authenticated={this.props.isLoggedIn && this.props.isAuth}
            />
            <Route exact path="/error" component={LoginErrorPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/loggedout" component={LoggedOut} />
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/redirect-doc" component={Redirect} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isLoggedIn = state.auth.isLoggedIn;
  const isAuth = state.auth.isAuth;
  const isIdle = state.auth.isIdle;
  const userData = state.auth.user;
  return {
    isLoggedIn,
    isAuth,
    isIdle,
    userData,
  };
}

export default withRouter(connect(mapStateToProps)(App));
