import React from 'react';
import { Link } from 'react-router-dom';
import { AccessPrompt } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components';

export default function LoggedOut() {
  return (
    <AccessPrompt>
      <div>You are logged out.</div>
      <Link to="/">Click here to log in again</Link>
    </AccessPrompt>
  );
}
