import axios from 'axios';
import Auth from './auth';

function dexfRequest(baseUrl, method, url, data) {
  return Auth.idToken().then((idToken) => {
    return axios({
      baseURL: baseUrl,
      headers: {
        Authorization: 'Bearer ' + idToken,
        'DEXF-API-KEY': window.discovery.apiKey,
      },
      method: method,
      url: url,
      data: data,
    });
  });
}

function makeService(serviceName) {
  return {
    post(path, data) {
      return dexfRequest(window.discovery[serviceName], 'post', path, data);
    },
    put(path, data) {
      return dexfRequest(window.discovery[serviceName], 'put', path, data);
    },
    patch(path, data) {
      return dexfRequest(window.discovery[serviceName], 'patch', path, data);
    },
    get(path) {
      return dexfRequest(window.discovery[serviceName], 'get', path);
    },
    del(path) {
      return dexfRequest(window.discovery[serviceName], 'delete', path);
    },
  };
}

export const start = makeService('start');
export const documentation = makeService('documentation');
