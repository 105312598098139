import { toast } from 'react-toastify';

export function toastError(error, extraInfo) {
  if (error && error.response) {
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else if (error.response.data.message && extraInfo) {
      toast.error(error.response.data.message + extraInfo);
    } else if (error.response.data.title) {
      toast.error(error.response.data.title);
    } else if (error) {
      toast.error('Something went wrong');
    }
  } else {
    toast.error('Something went wrong');
  }
}
