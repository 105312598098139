import * as ac from 'Store/actionCreators';
import { start } from './../../utils/network';
import { toastError } from 'Helpers';

export function getPong() {
  return async (dispatch, _) => {
    try {
      dispatch(ac.ping.init());
      let response = await start.get(`admin/ping`);
      dispatch(ac.ping.success(response.data));
    } catch (error) {
      toastError(error);
      dispatch(ac.ping.failure(error.response));
    }
  };
}
