import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Start extends React.Component {
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/home');
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    return <div>Redirecting...</div>;
  }
}

function mapStateToProps(state) {
  const isLoggedIn = state.auth.isLoggedIn;
  return {
    isLoggedIn
  };
}

export default withRouter(connect(mapStateToProps)(Start));
