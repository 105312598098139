import Cookies from 'js-cookie';

class AuthStorage {
  configure(settings) {
    const { prefix } = settings;
    this.idTokenName = prefix + 'idToken';
    this.accessTokenName = prefix + 'accessToken';
    this.refreshTokenName = prefix + 'refreshToken';
  }

  storeTokens(tokens) {
    window.localStorage.setItem(this.idTokenName, tokens.id_token);
    window.localStorage.setItem(this.accessTokenName, tokens.access_token);

    if (tokens.refresh_token) {
      window.localStorage.setItem(this.refreshTokenName, tokens.refresh_token);
    }
  }

  idToken() {
    return this._getToken(this.idTokenName);
  }

  accessToken() {
    return this._getToken(this.accessTokenName);
  }

  refreshToken() {
    return this._getToken(this.refreshTokenName);
  }

  _getToken(tokenName) {
    return window.localStorage.getItem(tokenName);
  }

  storePkce(pkce) {
    Cookies.set('pkce', pkce);
  }

  pkce() {
    const pkce = Cookies.get('pkce');
    Cookies.remove('pkce');
    return pkce;
  }

  clear() {
    window.localStorage.removeItem(this.idTokenName);
    window.localStorage.removeItem(this.accessTokenName);
    window.localStorage.removeItem(this.refreshTokenName);
  }

  clearAll() {
    const localStorageItems = { ...window.localStorage };
    let dexfKeys = Object.keys(localStorageItems);
    let allDexfTokens = dexfKeys.filter((item) => {
      if (
        item.includes('_accessToken') ||
        item.includes('_idToken') ||
        item.includes('_refreshToken')
      ) {
        return item;
      } else {
        return false;
      }
    });
    allDexfTokens.sort().map((item) => {
      window.localStorage.removeItem(item);
      return null;
    });
  }

  getServiceName() {
    const serviceName = window.location.pathname.split('/');
    return serviceName[1];
  }

  getPathAfterReload() {
    let serviceName = this.getServiceName();
    let sessionPath = sessionStorage.getItem('path_' + serviceName);
    if (sessionPath) {
      sessionStorage.removeItem('path_' + serviceName);
    }
    let path = sessionPath ? sessionPath : '/home';
    return path;
  }

  setSessionUrl() {
    if (!window.location.pathname.includes('callback')) {
      let serviceName = this.getServiceName();
      let currentPage = window.location.pathname + window.location.search + window.location.hash;
      currentPage = currentPage.replace('/' + serviceName + '/v1/ui', '');
      sessionStorage.setItem('path_' + serviceName, currentPage);
      console.log('path_' + serviceName, currentPage);
    }
  }
}

export default new AuthStorage();
