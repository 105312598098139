import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initDocumentationLogin } from 'Store/auth/actions';
import authStorage from '../../utils/authStorage';

function Redirect(props) {
  const dispatch = useDispatch();
  const { documentationCookieSet, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const redirect = true;
    if (isLoggedIn) {
      dispatch(initDocumentationLogin(redirect));
    } else {
      props.history.replace('/home');
    }
  }, [props.history, isLoggedIn, dispatch]);

  if (documentationCookieSet) {
    console.log(window.location);
    const urlSearchString = window.location.search;
    let urlParams = new URLSearchParams(urlSearchString);
    let page = urlParams.get('page');
    const url = window.location.origin + '/start/v1/ui/' + page;
    console.log('Go to', url);
    window.location.replace(url);
  }

  return <span>Redirecting...</span>;
}

export default withRouter(Redirect);
