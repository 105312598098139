import * as types from './actionTypes.js';

const initialState = {
  isLoggedIn: false,
  loading: false,
  user: {},
  isAuth: false,
  isManageOperation: false,
  userLoginErrorMessage: '',
  isIdle: false,
  documentationCookieSet: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      let isAuth = false;
      if (action.data['dexf_service']) {
        isAuth = true;
      }
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: action.data,
        isAuth: isAuth,
        userLoginErrorMessage: '',
        tokenExpiresAt: action.data.exp,
        isIdle: false,
      };
    case types.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        user: {},
        isAuth: false,
        userLoginErrorMessage: action.error.message,
        isIdle: false,
      };
    case types.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        user: {},
        isAuth: false,
        isIdle: false,
      };
    case types.USER_DOCUMENTATION_LOGIN_SUCCESS:
      return {
        ...state,
        documentationCookieSet: true,
      };
    case types.USER_IDLE:
      return {
        ...state,
        isIdle: true,
      };
    case types.USER_ACTIVE:
      return {
        ...state,
        isIdle: false,
      };
    default:
      return state;
  }
}

export function getLoggedIn(state) {
  const isLoggedIn = state.auth.isLoggedIn;
  return isLoggedIn;
}
