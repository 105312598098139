import axios from 'axios';
import jwtDecode from 'jwt-decode';
import authStorage from './authStorage';
import { createHash, randomBytes } from 'crypto-browserify';
import Queue from 'p-queue';
import idleHandler from './idleHandler';

class Auth {
  configure(settings) {
    const { domain, clientId, redirectUri, logoutUri } = settings;
    this.domain = domain;
    this.clientId = clientId;
    this.redirectUri = redirectUri;
    this.logoutUri = logoutUri;
    this.tokenQueue = new Queue({ concurrency: 1 });
    authStorage.configure({ prefix: clientId + '_' });
  }

  signIn() {
    const verifier = Auth._base64(randomBytes(32));
    authStorage.storePkce(verifier);

    const challenge = Auth._base64(Auth._sha256(verifier));

    const data = {
      redirect_uri: this.redirectUri,
      response_type: 'code',
      client_id: this.clientId,
      code_challenge: challenge,
      code_challenge_method: 'S256',
    };

    const parameters = Auth._createParameters(data);

    window.location.href = `${this.domain}/oauth2/authorize?${parameters}`;
  }

  idToken() {
    return this.tokenQueue.add(() => this._tokenPromise());
  }

  _tokenPromise() {
    return new Promise((resolve, reject) => {
      const idToken = authStorage.idToken();
      const refreshToken = authStorage.refreshToken();

      if (Auth._tokenIsValid(idToken)) {
        resolve(idToken);
      } else if (refreshToken) {
        const data = {
          grant_type: 'refresh_token',
          client_id: this.clientId,
          refresh_token: refreshToken,
        };

        const parameters = Auth._createParameters(data);
        return axios({
          url: `${this.domain}/oauth2/token`,
          method: 'post',
          data: parameters,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then((result) => authStorage.storeTokens(result.data))
          .then(() => resolve(authStorage.idToken()))
          .catch(() => {
            authStorage.clear();
            reject('No logged in user');
          });
      } else {
        authStorage.clear();
        reject('No logged in user');
      }
    });
  }

  callback() {
    const parameters = new URLSearchParams(window.location.search);

    const data = {
      grant_type: 'authorization_code',
      code: parameters.get('code'),
      redirect_uri: this.redirectUri,
      client_id: this.clientId,
      code_verifier: authStorage.pkce(),
    };

    const body = Auth._createParameters(data);

    return axios({
      url: `${this.domain}/oauth2/token`,
      method: 'post',
      data: body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((result) => {
      authStorage.storeTokens(result.data);
    });
  }

  signOut() {
    idleHandler.stop();
    // authStorage.clear();
    authStorage.clearAll();

    const data = {
      client_id: this.clientId,
      logout_uri: this.logoutUri,
    };

    const parameters = Auth._createParameters(data);
    window.open(`${this.domain}/logout?${parameters}`, '_self');
  }

  signOutSingle() {
    idleHandler.stop();
    authStorage.clear();
    const data = {
      client_id: this.clientId,
      logout_uri: this.logoutUri,
    };
    const parameters = Auth._createParameters(data);
    window.open(`${this.domain}/logout?${parameters}`, '_self');
  }

  static _sha256(buffer) {
    return createHash('sha256').update(buffer).digest();
  }

  static _base64(value) {
    return value.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  static _tokenIsValid(token) {
    return token && jwtDecode(token).exp > Auth._nowPlus30seconds();
  }

  static _nowPlus30seconds() {
    return Date.now() / 1000 + 30;
  }

  static _createParameters(data) {
    return Object.entries(data)
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&');
  }
}

export default new Auth();
