import React from 'react';
import { Link } from 'react-router-dom';
import { AccessPrompt } from '@inter-ikea/cbf-re-ipex-dexf-lib-ui-components';

export default function LoginErrorPage() {
  return (
    <AccessPrompt>
      <div>There was an error logging you in.</div>
      <Link to="/">Try again</Link>
    </AccessPrompt>
  );
}
