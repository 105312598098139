import * as types from './actionTypes';
import { documentation } from './../../utils/network';
import jwtDecode from 'jwt-decode';
import authStorage from '../../utils/authStorage';

export function initLogin(token) {
  return (dispatch) => {
    dispatch({ type: types.USER_LOGIN_INIT });
    const decodedToken = jwtDecode(token);
    dispatch({ type: types.USER_LOGIN_SUCCESS, data: decodedToken });
  };
}

export function initDocumentationLogin(redirect) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.USER_DOCUMENTATION_LOGIN_INIT });
      let response = await documentation.get(`/ui/documentation/login`);
      if (response.status === 200) {
        if (redirect) {
          authStorage.getPathAfterReload();
        } else {
          window.location.replace(
            '/start/v1/ui/documentation/Digital-Experience-Foundation_857276568.html'
          );
        }
        dispatch({ type: types.USER_DOCUMENTATION_LOGIN_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: types.USER_DOCUMENTATION_LOGIN_FAILURE });
    }
  };
}

export function setUserActive() {
  return (dispatch) => dispatch({ type: types.USER_ACTIVE });
}
