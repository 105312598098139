function makeApiActionCreator(name) {
  return {
    init: () => ({ type: name + '_INIT' }),
    abort: () => ({ type: name + '_ABORT' }),
    success: content => ({ type: name + '_SUCCESS', data: content }),
    failure: msg => ({ type: name + '_FAILURE', error: msg })
  };
}
export const ping = makeApiActionCreator('ping.PING');

// Other
export const progressBar = {
  init: () => ({ type: 'ping.PROGRESS_BAR_INIT' }),
  update: val => ({ type: 'ping.PROGRESS_BAR_UPDATE', data: val })
};
